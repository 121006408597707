/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it


exports.onInitialClientRender = () => {
  const hasHashInUrl = window.location.hash
  
  if(!hasHashInUrl) {
    window.scrollTo(0, 0);
  }
};

exports.shouldUpdateScroll = () => [0, 0];


/*
exports.onRouteUpdate = ({ location, prevLocation }) => {
  var body = document.getElementById('scroll-root');
  // NB -Body will not exist if the current page is 
  // the development version of the 404 page.
  if (body) {
    body.scrollTo(0, 0);
  }
};
*/