export const onRouteUpdate = (
    { location },
    pluginOptions = { stripQueryString: false }
  ) => {
    const domElem = document.querySelector(`meta[property='og:url']`)
    const existingValue = domElem.getAttribute(`content`)
    const baseProtocol = domElem.getAttribute(`data-baseProtocol`)
    const baseHost = domElem.getAttribute(`data-baseHost`)
    
    if (existingValue && baseProtocol && baseHost) {
      let value = `${baseProtocol}//${baseHost}${location.pathname}`
  
      const { stripQueryString } = pluginOptions
  
      if (!stripQueryString) {
        value += location.search
      }
  
      value += location.hash
  
      domElem.setAttribute(`content`, `${value}`)
    }
  }